@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@font-face {
    font-display: block;
}

@layer components {
    .highlight {
        @apply bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent
    }
}

:root {
    --var-rmc-base-1: #2A2728; // text
    --var-rmc-base-2: #7A7174; // text-secondary
    --var-rmc-base-3: #DFE4EA; // border

    --var-rmc-primary-1: #E44F0B; // accent-1
    --var-rmc-primary-2: #F78E5E; // accent
    --var-rmc-primary-3: #F9B18F; // accent-2
    --var-rmc-primary-4: #FFFAF3; // background
    --var-rmc-primary-5: #FBBC68; // yellow

    --var-rmc-red-1: #B21818; // error-1
    --var-rmc-red-2: #E74242; // error

    --var-rmc-green-1: #0F7134; // success-1
    --var-rmc-green-2: #1BB157; // success
    --var-rmc-green-3: #DAF8E6; // successContainer

    --var-rmc-neutral-1: #111928; // neutral
    --var-rmc-neutral-2: #637381; // neutral-1
    --var-rmc-neutral-3: #F1F0F0;

    --var-rmc-secondary-1: #FDEDB3; // notes
    --var-rmc-secondary-2: #FDEED8; // background-1
    --var-rmc-secondary-3: #E8E8E8; // disabled

    --var-rmc-surface: #FFFFFF; // surface
    --var-rmc-on-surface: #807973; // on-surface

    --tw-ring-color: var(--var-rmc-on-surface);
}

.reader {
    h1 {
        @apply hidden
    }
    h2 {
        @apply mt-12 mb-5 text-2xl font-semibold text-gray-900
    }
    h3 {
        @apply mt-10 mb-4 text-xl font-semibold text-gray-900
    }
    h4 {
        @apply mt-8 mb-3 text-base font-semibold text-gray-900
    }
    p {
        @apply text-gray-700 my-5
    }
    figure {
        & {
            @apply relative
        }
        img {
            @apply rounded rounded-xl mt-4
        }
        figcaption {
            @apply absolute bottom-0 px-3 left-0 bg-opacity-70 right-0  h-5 bg-white text-sm text-gray-500 mt-2
        }
    }
    :first-child {
        @apply mt-0
    }
}

.tooltip {
    @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
    @apply visible z-50;
}

@layer utilities {

    $themeVariables: (
            "base-1": --var-rmc-base-1,
            "base-2": --var-rmc-base-2,
            "base-3": --var-rmc-base-3,
            "primary-1": --var-rmc-primary-1,
            "primary-2": --var-rmc-primary-2,
            "primary-3": --var-rmc-primary-3,
            "primary-4": --var-rmc-primary-4,
            "primary-5": --var-rmc-primary-5,
            "red-1": --var-rmc-red-1,
            "red-2": --var-rmc-red-2,
            "green-1": --var-rmc-green-1,
            "green-2": --var-rmc-green-2,
            "green-3": --var-rmc-green-3,
            "neutral-1": --var-rmc-neutral-1,
            "neutral-2": --var-rmc-neutral-2,
            "neutral-3": --var-rmc-neutral-3,
            "secondary-1": --var-rmc-secondary-1,
            "secondary-2": --var-rmc-secondary-2,
            "secondary-3": --var-rmc-secondary-3,
            "surface": --var-rmc-surface,
            "on-surface": --var-rmc-on-surface,
    );

    @each $name, $value in $themeVariables {
        .rmc-text-#{$name} {
            color: var($value);
        }

        .rmc-border-#{$name} {
            border-color: var($value)
        }

        .rmc-bg-#{$name} {
            background-color: var($value)
        }

        .rmc-ring-#{$name} {
            --tw-ring-color: var($value)
        }

        .rmc-fill-#{name} {
            fill: var($value)
        }
    }
}


input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: white; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: white;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 32px;
    border-radius: 20px;
    background: var(--var-rmc-primary-2);
    background-image: url('../public/arrows.svg'); /* Add the icon here */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px; /* Adjust the size of the icon */
    cursor: pointer;
    margin-top: -1px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

.card-shadow {
    box-shadow: 0px 39px 23px -27px rgba(0, 0, 0, 0.04);
}